import { Outlet } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { Container, Content } from "./styles";
import { Header } from "./Header";

export const UniversalLayout = () => {
  return (
    <Container>
      <Header />
      <Content>
        <div>
          <Sidebar />
        </div>
        <div>
          <Outlet />
        </div>
      </Content>
    </Container>
  );
};
