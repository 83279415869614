import { Link, useLocation } from "react-router-dom";
import { NavItemContainer } from "./styles";

interface INavItem {
  name: string;
  path: string;
}

export const NavItem = ({ name, path }: INavItem) => {
  const location = useLocation();

  const isSelected =
    name === "Home"
      ? location.pathname.length === 1
      : location.pathname.includes(path);

  return (
    <NavItemContainer isSelected={isSelected}>
      <Link to={path}>{name}</Link>
    </NavItemContainer>
  );
};
