import styled from "styled-components";

export const EndpointsContainer = styled.div`
  height: 100%;
  width: 60vw;
  overflow: auto;
  padding-top: 20px;

  .info {
    margin: unset;
  }

  .title {
    font-size: 1.9rem !important;
    font-weight: 550;
  }
`;
