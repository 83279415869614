import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Inter';
    color: #3b4151;

    &::-webkit-scrollbar { 
      width: 0;
      background: transparent;
    }
    scrollbar-width: none;

    overscroll-behavior: none;

    @media screen and (max-width:767px) {
      overflow: hidden;
    };

    @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
      overflow: hidden;
    };
    
  }
 
`;
