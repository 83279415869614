import { AuthContainer, Container, Title } from "./styles";

export const Auth = () => {
  return (
    <AuthContainer>
      <Title>Autenticação</Title>
      <Container>
        <label>
          Para fazer uso da API do Retina, é necessário o envio de um token de
          autenticação junto em cada requisição. O token pode ser gerado dentro
          do Retina e é feito através do caminho abaixo:
          <br />
          <br />
          Meu Perfil &gt; Configurações &gt; Integração &gt; API &gt; Gerar novo
          Token.
          <br />
          <br />
          Com o token gerado, copie e insira-o no header Authorization no
          formato Bearer + token conforme exemplo em Javascript abaixo:
        </label>
      </Container>
      <img
        src={require("../../images/examplerequest.png")}
        alt="example of requisition"
      />
    </AuthContainer>
  );
};
