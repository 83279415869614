import styled from "styled-components";

export const NavItemContainer = styled.div<{ isSelected?: boolean }>`
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 3px;

  a {
    border-radius: 1rem;
    width: 100%;
    padding: 12px;
    text-decoration: none;
    background: ${(props) => props.isSelected && "#ebe3e3"};
    transition: 0.2s ease-in-out;
    color: #3b4151;
  }

  :hover {
    background: #cfc9c9 !important;
  }
`;
