import styled from "styled-components";

export const SidebarContainer = styled.aside<{ closed?: boolean }>`
  height: 100%;
  width: ${({ closed }) => (closed ? "5rem" : "15.5rem")};
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 0.05rem;
  border-right: 1px solid #ebe3e3;
`;
