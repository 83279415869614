import SwaggerUI from "swagger-ui-react";
import json from "../../swagger.json";
import { EndpointsContainer } from "./styles";

interface PathItem {
  get?: any;
  post?: any;
  put?: any;
  delete?: any;
  [method: string]: any;
}

interface SwaggerJson {
  servers: { url: string }[];
  paths: { [path: string]: PathItem };
}

export const Endpoints = () => {
  const envJson: SwaggerJson = json;

  envJson.servers[0].url = process.env.REACT_APP_API_URL || "";

  if (process.env.REACT_APP_API_URL === "https://api.ibbx.tech") {
    Object.keys(envJson?.paths).forEach((path) => {
      if (path.includes("/gateways")) {
        delete envJson?.paths[path];
      }
    });
  }

  return (
    <EndpointsContainer>
      <SwaggerUI spec={envJson} />
    </EndpointsContainer>
  );
};
