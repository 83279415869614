import { WebhookContainer, Container, Title, Subtitle } from "./styles";

export const WebHooks = () => {
  return (
    <WebhookContainer>
      <Title>Webhooks</Title>
      <Container>
        <label>
          Para utilizar os Webhooks do Retina, é preciso registrar o webhook. O
          registro do webhook pode ser realizado no próprio Retina, seguindo os
          passos abaixo:
          <br />
          <br />
          Meu Perfil &gt; Configurações &gt; Integração &gt; Gatilhos
          <br />
          <br />
          <Subtitle>Ocorrência</Subtitle>
          Exemplo de valor que podem ser enviados:
          <br />
          <img
            src={require("../../images/webhookResponse.png")}
            alt="example of requisition"
          />
        </label>
      </Container>
    </WebhookContainer>
  );
};
