import { Container, MainContainer, Title } from "./styles";

export const Home = () => {
  return (
    <MainContainer>
      <Title>Introdução à API do Retina</Title>
      <Container>
        O Retina oferece a seus clientes um serviço de integração que lhes permite acessar suas
        informações atualizadas, conectar aplicações
        externas e ter acesso instantâneo a seus dados.
        <br />
        <br />A API do Retina é baseada em serviços web REST e o formato de
        transferência de dados suportado é JSON. Todas as respostas são enviadas
        neste formato e, portanto, as solicitações devem ser enviadas neste
        formato.
        <br />
        REST é um conjunto bem definido de operações que se aplicam a todos os
        recursos do padrão HTTP, definindo ele mesmo um pequeno conjunto de
        operações, as mais importantes das quais são POST, GET, PUT e DELETE. As
        operações são equivalentes às operações CRUD em bancos de dados (Create,
        Read, Update, Delete).
      </Container>
    </MainContainer>
  );
};
