import styled from "styled-components";

export const HeaderContainer = styled.div`
  border: 1px solid #ebe3e3;
  width: 100%;
  height: 3.5rem;
`;

export const NavBarLogoContent = styled.div`
  background-color: #f37035;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
`;
