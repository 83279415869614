import { Routes as RDRoutes, Route } from "react-router-dom";
import { UniversalLayout } from "../pages/UniversalLayout";
import { Endpoints } from "../pages/Endpoints";
import { Home } from "../pages/Home";
import { Auth } from "../pages/Auth";
import { WebHooks } from "../pages/WebHooks";

export const Routes = () => {
  return (
    <RDRoutes>
      <Route path="/" element={<UniversalLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="authentication" element={<Auth />} />
        <Route path="webhooks" element={<WebHooks />} />
        <Route path="endpoints" element={<Endpoints />} />
      </Route>
    </RDRoutes>
  );
};
