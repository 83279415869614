import { NavItem } from "./NavItem";
import { SidebarContainer } from "./styles";

export const Sidebar = () => {
  return (
    <SidebarContainer>
      <NavItem name="Home" path={"/"} />
      <NavItem name="Autenticação" path={"/authentication"} />
      <NavItem name="Endpoints" path={"/endpoints"} />
      <NavItem name="Webhooks" path={"/webhooks"} />
    </SidebarContainer>
  );
};
