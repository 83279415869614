import SwaggerUI from "swagger-ui-react";
import GlobalStyle from "./styles/global";
import { ThemeProvider } from "styled-components";

import "swagger-ui-react/swagger-ui.css";
import { Routes } from "./route/Routes";
import theme from "./styles/global";

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  );
};
