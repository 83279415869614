import { HeaderContainer, NavBarLogoContent } from "./styles";
const LogoWhite = require("../../../images/ibbxWhite.png");

export const Header = () => {
  return (
    <HeaderContainer>
      <NavBarLogoContent>
        <img src={LogoWhite} alt="logo" />
      </NavBarLogoContent>
    </HeaderContainer>
  );
};
